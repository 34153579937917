const actions = {
  FETCH_FOLLOWUPS: "FETCH_FOLLOWUPS",
  FETCH_FOLLOWUPS_SUCCESS: "FETCH_FOLLOWUPS_SUCCESS",
  FETCH_FOLLOWUPS_FAILURE: "FETCH_FOLLOWUPS_FAILURE",

  ADD_FOLLOWUPS: "ADD_FOLLOWUPS",
  ADD_FOLLOWUPS_SUCCESS: "ADD_FOLLOWUPS_SUCCESS",
  ADD_FOLLOWUPS_FAILURE: "ADD_FOLLOWUPS_FAILURE",

  UPDATE_FOLLOWUPS: "UPDATE_FOLLOWUPS",
  UPDATE_FOLLOWUPS_SUCCESS: "UPDATE_FOLLOWUPS_SUCCESS",
  UPDATE_FOLLOWUPS_FAILURE: "UPDATE_FOLLOWUPS_FAILURE",

  DELETE_FOLLOWUPS: "DELETE_FOLLOWUPS",
  DELETE_FOLLOWUPS_SUCCESS: "DELETE_FOLLOWUPS_SUCCESS",
  DELETE_FOLLOWUPS_FAILURE: "DELETE_FOLLOWUPS_FAILURE",

  ADD_FOLLOWUPS_COMMENT: "ADD_FOLLOWUPS_COMMENT",
  ADD_FOLLOWUPS_COMMENT_SUCCESS: "ADD_FOLLOWUPS_COMMENT_SUCCESS",
  ADD_FOLLOWUPS_COMMENT_FAILURE: "ADD_FOLLOWUPS_COMMENT_FAILURE",

  UPDATE_FOLLOWUPS_COMMENT: "UPDATE_FOLLOWUPS_COMMENT",
  UPDATE_FOLLOWUPS_COMMENT_SUCCESS: "UPDATE_FOLLOWUPS_COMMENT_SUCCESS",
  UPDATE_FOLLOWUPS_COMMENT_FAILURE: "UPDATE_FOLLOWUPS_COMMENT_FAILURE",

  DELETE_FOLLOWUPS_COMMENT: "DELETE_FOLLOWUPS_COMMENT",
  DELETE_FOLLOWUPS_COMMENT_SUCCESS: "DELETE_FOLLOWUPS_COMMENT_SUCCESS",
  DELETE_FOLLOWUPS_COMMENT_FAILURE: "DELETE_FOLLOWUPS_COMMENT_FAILURE",
};

export default actions;
