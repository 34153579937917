import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getSalesOffer(action) {
  try {
    const url = action.id
      ? `sales/offer?id=${action.id}&role=${action.role}`
      : `sales/offer?role=${action.role}`;
    const response = yield callApi(() => getRequest(url));
    yield put({
      type: actions.FETCH_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_OFFER_FAILURE });
  }
}

function* addSalesOffer(action) {
  try {
    const response = yield callApi(() =>
      postRequest("sales/offer", action.payload)
    );
    yield put({
      type: actions.ADD_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_OFFER_FAILURE });
  }
}

function* editSalesOffer(action) {
  try {
    yield callApi(() =>
      putRequest(`sales/offer?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_OFFER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_OFFER_FAILURE });
  }
}

function* deleteSalesOffer(action) {
  try {
    yield callApi(() => deleteRequest(`sales/offer?id=${action.id}`));
    yield put({
      type: actions.DELETE_OFFER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_OFFER_FAILURE });
  }
}

function* getSalesOfferTemplates(action) {
  try {
    const response = yield callApi(() => getRequest("sales/offer-templates"));
    yield put({
      type: actions.FETCH_OFFER_TEMPLATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_OFFER_TEMPLATES_FAILURE });
  }
}
function* getSalesOfferAddress(action) {
  try {
    const url = action.id
      ? `sales/offer-addresses?id=${action.id}&role=${action.role}`
      : `sales/offer-addresses?role=${action.role}`;
    const response = yield callApi(() => getRequest(url));
    yield put({
      type: actions.FETCH_OFFER_ADDRESSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_OFFER_ADDRESSES_FAILURE });
  }
}

function* addSalesOfferComment(action) {
  try {
    yield callApi(() =>
      postRequest(`sales/offer/comment?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.ADD_OFFER_COMMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_OFFER_COMMENT_FAILURE });
  }
}

function* editSalesOfferComment(action) {
  try {
    yield callApi(() =>
      putRequest(`sales/offer/comment?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_OFFER_COMMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_OFFER_COMMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_OFFER, getSalesOffer)]);
  yield all([takeLatest(actions.ADD_OFFER, addSalesOffer)]);
  yield all([takeLatest(actions.UPDATE_OFFER, editSalesOffer)]);
  yield all([takeLatest(actions.DELETE_OFFER, deleteSalesOffer)]);
  yield all([
    takeLatest(actions.FETCH_OFFER_TEMPLATES, getSalesOfferTemplates),
  ]);
  yield all([takeLatest(actions.FETCH_OFFER_ADDRESSES, getSalesOfferAddress)]);
  yield all([takeLatest(actions.ADD_OFFER_COMMENT, addSalesOfferComment)]);
  yield all([takeLatest(actions.UPDATE_OFFER_COMMENT, editSalesOfferComment)]);
}
