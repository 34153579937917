const actions = {
  FETCH_OFFER: "FETCH_OFFER",
  FETCH_OFFER_SUCCESS: "FETCH_OFFER_SUCCESS",
  FETCH_OFFER_FAILURE: "FETCH_OFFER_FAILURE",

  ADD_OFFER: "ADD_OFFER",
  ADD_OFFER_SUCCESS: "ADD_OFFER_SUCCESS",
  ADD_OFFER_FAILURE: "ADD_OFFER_FAILURE",

  UPDATE_OFFER: "UPDATE_OFFER",
  UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",
  UPDATE_OFFER_FAILURE: "UPDATE_OFFER_FAILURE",

  DELETE_OFFER: "DELETE_OFFER",
  DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",
  DELETE_OFFER_FAILURE: "DELETE_OFFER_FAILURE",

  FETCH_OFFER_TEMPLATES: "FETCH_OFFER_TEMPLATES",
  FETCH_OFFER_TEMPLATES_SUCCESS: "FETCH_OFFER_TEMPLATES_SUCCESS",
  FETCH_OFFER_TEMPLATES_FAILURE: "FETCH_OFFER_TEMPLATES_FAILURE",

  FETCH_OFFER_ADDRESSES: "FETCH_OFFER_ADDRESSES",
  FETCH_OFFER_ADDRESSES_SUCCESS: "FETCH_OFFER_ADDRESSES_SUCCESS",
  FETCH_OFFER_ADDRESSES_FAILURE: "FETCH_OFFER_ADDRESSES_FAILURE",

  ADD_OFFER_COMMENT: "ADD_OFFER_COMMENT",
  ADD_OFFER_COMMENT_SUCCESS: "ADD_OFFER_COMMENT_SUCCESS",
  ADD_OFFER_COMMENT_FAILURE: "ADD_OFFER_COMMENT_FAILURE",

  UPDATE_OFFER_COMMENT: "UPDATE_OFFER_COMMENT",
  UPDATE_OFFER_COMMENT_SUCCESS: "UPDATE_OFFER_COMMENT_SUCCESS",
  UPDATE_OFFER_COMMENT_FAILURE: "UPDATE_OFFER_COMMENT_FAILURE",
};

export default actions;
