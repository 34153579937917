import actions from "./actions";

const initialState = {
  salesOffer: [],
  isFetchingSalesOffer: false,

  isChangedAddSalesOffer: null,

  salesOfferTemplates: [],
  isFetchingSalesOfferTemplates: false,

  createdSalesOffer: null,

  salesOfferAddresses: [],
  isFetchingSalesOfferAddresses: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_OFFER:
      return {
        ...state,
        isFetchingSalesOffer: true,
        isChangedAddSalesOffer: null,
      };
    case actions.FETCH_OFFER_SUCCESS:
      return {
        ...state,
        isFetchingSalesOffer: false,
        salesOffer: action.payload,
      };
    case actions.FETCH_OFFER_FAILURE:
      return { ...state, isFetchingSalesOffer: false };

    case actions.ADD_OFFER:
      return {
        ...state,
        isChangedAddSalesOffer: null,
        createdSalesOffer: null,
      };
    case actions.ADD_OFFER_SUCCESS:
      return {
        ...state,
        isChangedAddSalesOffer: true,
        createdSalesOffer: action.payload,
      };
    case actions.ADD_OFFER_FAILURE:
      return { ...state, isChangedAddSalesOffer: false };

    case actions.UPDATE_OFFER:
      return { ...state, isChangedAddSalesOffer: null };
    case actions.UPDATE_OFFER_SUCCESS:
      return { ...state, isChangedAddSalesOffer: true };
    case actions.UPDATE_OFFER_FAILURE:
      return { ...state, isChangedAddSalesOffer: false };

    case actions.DELETE_OFFER:
      return { ...state, isChangedAddSalesOffer: null };
    case actions.DELETE_OFFER_SUCCESS:
      return { ...state, isChangedAddSalesOffer: true };
    case actions.DELETE_OFFER_FAILURE:
      return { ...state, isChangedAddSalesOffer: false };

    case actions.FETCH_OFFER_TEMPLATES:
      return {
        ...state,
        isFetchingSalesOfferTemplates: true,
      };
    case actions.FETCH_OFFER_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetchingSalesOfferTemplates: false,
        salesOfferTemplates: action.payload,
      };
    case actions.FETCH_OFFER_TEMPLATES_FAILURE:
      return { ...state, isFetchingSalesOfferTemplates: false };

    case actions.FETCH_OFFER_ADDRESSES:
      return {
        ...state,
        isFetchingSalesOfferAddresses: true,
      };
    case actions.FETCH_OFFER_ADDRESSES_SUCCESS:
      return {
        ...state,
        isFetchingSalesOfferAddresses: false,
        salesOfferAddresses: action.payload,
      };
    case actions.FETCH_OFFER_ADDRESSES_FAILURE:
      return { ...state, isFetchingSalesOfferAddresses: false };

    case actions.ADD_OFFER_COMMENT:
      return {
        ...state,
        isChangedAddSalesOffer: null,
      };
    default:
      return state;
  }
}

export default Reducer;
