import actions from "./actions";

const initialState = {
  salesFollowups: [],
  isFetchingSalesFollowups: false,

  isChangedAddSalesFollowups: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_FOLLOWUPS:
      return {
        ...state,
        isFetchingSalesFollowups: true,
        isChangedAddSalesFollowups: null,
      };
    case actions.FETCH_FOLLOWUPS_SUCCESS:
      return {
        ...state,
        isFetchingSalesFollowups: false,
        salesFollowups: action.payload,
      };
    case actions.FETCH_FOLLOWUPS_FAILURE:
      return { ...state, isFetchingSalesFollowups: false };

    case actions.ADD_FOLLOWUPS:
      return { ...state, isChangedAddSalesFollowups: null };
    case actions.ADD_FOLLOWUPS_SUCCESS:
      return { ...state, isChangedAddSalesFollowups: true };
    case actions.ADD_FOLLOWUPS_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };

    case actions.UPDATE_FOLLOWUPS:
      return { ...state, isChangedAddSalesFollowups: null };
    case actions.UPDATE_FOLLOWUPS_SUCCESS:
      return { ...state, isChangedAddSalesFollowups: true };
    case actions.UPDATE_FOLLOWUPS_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };

    case actions.DELETE_FOLLOWUPS:
      return { ...state, isChangedAddSalesFollowups: null };
    case actions.DELETE_FOLLOWUPS_SUCCESS:
      return { ...state, isChangedAddSalesFollowups: true };
    case actions.DELETE_FOLLOWUPS_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };

    case actions.ADD_FOLLOWUPS_COMMENT:
      return {
        ...state,
        isChangedAddSalesFollowups: null,
      };
    case actions.ADD_FOLLOWUPS_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedAddSalesFollowups: true,
      };
    case actions.ADD_FOLLOWUPS_COMMENT_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };

    case actions.UPDATE_FOLLOWUPS_COMMENT:
      return {
        ...state,
        isChangedAddSalesFollowups: null,
      };
    case actions.UPDATE_FOLLOWUPS_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedAddSalesFollowups: true,
      };
    case actions.UPDATE_FOLLOWUPS_COMMENT_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };

    case actions.DELETE_FOLLOWUPS_COMMENT:
      return {
        ...state,
        isChangedAddSalesFollowups: null,
      };
    case actions.DELETE_FOLLOWUPS_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedAddSalesFollowups: true,
      };
    case actions.DELETE_FOLLOWUPS_COMMENT_FAILURE:
      return { ...state, isChangedAddSalesFollowups: false };
    default:
      return state;
  }
}

export default Reducer;
