import "./i18n";
import React, { Suspense, lazy, useCallback, useEffect } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "components/ui/loader/Loader";
import { useCookies } from "react-cookie";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { AntConfigProps } from "config/AntConfig";
import { useTranslation } from "react-i18next";
import nb_NO from "antd/lib/locale/nb_NO";
import en_GB from "antd/lib/locale/en_GB";

const CustomerRoutes = lazy(() => import("routes/Customer"));
const AdminRoutes = lazy(() => import("routes/Admin"));
const AuthRoutes = lazy(() => import("routes/Auth"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/autentisering/*" element={<AuthRoutes />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/*" element={<CustomerRoutes />} />
    </Route>
  )
);

function App() {
  const [cookies] = useCookies("token");

  const { i18n } = useTranslation();

  const currentLanguage = useCallback(() => {
    return i18n.language;
  }, [i18n]);

  useEffect(() => {
    if (cookies.token === undefined) {
      localStorage.removeItem("user");
    }
  }, [cookies]);

  console.log("ttttttttt", currentLanguage());
  return (
    <ConfigProvider
      {...AntConfigProps}
      locale={currentLanguage() === "no-NO" ? nb_NO : en_GB}
    >
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
