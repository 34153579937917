import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getSalesFollowups(action) {
  try {
    const url = `sales/follow-ups?type=${action.role}`;

    const response = yield callApi(() => getRequest(url));
    yield put({
      type: actions.FETCH_FOLLOWUPS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_FOLLOWUPS_FAILURE });
  }
}

function* addSalesFollowups(action) {
  try {
    yield callApi(() => postRequest("sales/follow-ups", action.payload));
    yield put({
      type: actions.ADD_FOLLOWUPS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_FOLLOWUPS_FAILURE });
  }
}

function* editSalesFollowups(action) {
  try {
    yield callApi(() =>
      putRequest(`sales/follow-ups?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_FOLLOWUPS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_FOLLOWUPS_FAILURE });
  }
}

function* deleteSalesFollowups(action) {
  try {
    yield callApi(() => deleteRequest(`sales/follow-ups?id=${action.id}`));
    yield put({
      type: actions.DELETE_FOLLOWUPS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_FOLLOWUPS_FAILURE });
  }
}

function* addSalesFollowupsComments(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `sales/follow-up/comment?followupId=${action.followId}`,
        action.payload
      )
    );
    yield put({
      type: actions.ADD_FOLLOWUPS_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_FOLLOWUPS_COMMENT_FAILURE });
  }
}
function* updateSalesFollowupsComments(action) {
  try {
    const url = `sales/follow-up/comment?id=${action.id}`;
    const response = yield callApi(() => putRequest(url, action.payload));
    yield put({
      type: actions.UPDATE_FOLLOWUPS_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_FOLLOWUPS_COMMENT_FAILURE });
  }
}

function* deleteSalesFollowupsComments(action) {
  try {
    yield callApi(() =>
      deleteRequest(`sales/follow-up/comment?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.DELETE_FOLLOWUPS_COMMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_FOLLOWUPS_COMMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_FOLLOWUPS, getSalesFollowups)]);
  yield all([takeLatest(actions.ADD_FOLLOWUPS, addSalesFollowups)]);
  yield all([takeLatest(actions.UPDATE_FOLLOWUPS, editSalesFollowups)]);
  yield all([takeLatest(actions.DELETE_FOLLOWUPS, deleteSalesFollowups)]);
  yield all([
    takeLatest(actions.ADD_FOLLOWUPS_COMMENT, addSalesFollowupsComments),
  ]);
  yield all([
    takeLatest(actions.UPDATE_FOLLOWUPS_COMMENT, updateSalesFollowupsComments),
  ]);
  yield all([
    takeLatest(actions.DELETE_FOLLOWUPS_COMMENT, deleteSalesFollowupsComments),
  ]);
}
