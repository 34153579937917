import actions from "./actions";

const initialState = {
  buildingContact: [],
  buildingConnections: [],
  isFetchingBuildingContact: false,
  isFetchingBuildingConnections: false,

  isChangedAddBuildingContact: null,
  createdBuildingContact: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CONTACT:
      return {
        ...state,
        buildingContact: [],
        isFetchingBuildingContact: true,
        isChangedAddBuildingContact: null,
      };
    case actions.FETCH_BUILDING_CONTACT_SUCCESS:
      return {
        ...state,
        isFetchingBuildingContact: false,
        buildingContact: action.payload,
      };
    case actions.FETCH_BUILDING_CONTACT_FAILURE:
      return { ...state, isFetchingBuildingContact: false };

    case actions.FETCH_BUILDING_CONNECTIONS:
      return {
        ...state,
        buildingConnections: [],
        isFetchingBuildingConnections: true,
      };
    case actions.FETCH_BUILDING_CONNECTIONS_SUCCESS:
      return {
        ...state,
        isFetchingBuildingConnections: false,
        buildingConnections: action.payload,
      };
    case actions.FETCH_BUILDING_CONNECTIONS_FAILURE:
      return { ...state, isFetchingBuildingConnections: false };

    case actions.ADD_BUILDING_CONTACT:
      return {
        ...state,
        isChangedAddBuildingContact: null,
        createdBuildingContact: null,
      };
    case actions.ADD_BUILDING_CONTACT_SUCCESS:
      return {
        ...state,
        isChangedAddBuildingContact: true,
        createdBuildingContact: action.payload,
      };
    case actions.ADD_BUILDING_CONTACT_FAILURE:
      return { ...state, isChangedAddBuildingContact: false };

    case actions.UPDATE_BUILDING_CONTACT:
      return { ...state, isChangedAddBuildingContact: null };
    case actions.UPDATE_BUILDING_CONTACT_SUCCESS:
      return { ...state, isChangedAddBuildingContact: true };
    case actions.UPDATE_BUILDING_CONTACT_FAILURE:
      return { ...state, isChangedAddBuildingContact: false };

    case actions.DELETE_BUILDING_CONTACT:
      return { ...state, isChangedAddBuildingContact: null };
    case actions.DELETE_BUILDING_CONTACT_SUCCESS:
      return { ...state, isChangedAddBuildingContact: true };
    case actions.DELETE_BUILDING_CONTACT_FAILURE:
      return { ...state, isChangedAddBuildingContact: false };

    case actions.RESET_BUILDING_CONTACT:
      return { ...state, isChangedAddBuildingContact: null };

    default:
      return state;
  }
}

export default Reducer;
