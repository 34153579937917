export const AntConfigProps = {
  theme: {
    token: {
      colorPrimary: "#ff8d00",
      colorLink: "#ff8d00",
      borderRadius: 0,
      borderRadiusLG: 0,
      borderRadiusSM: 4,
      colorBgLayout: "#f5f5f5",
      colorBgContainer: "#fff",
      fontSize: 14,
      fontFamily: "Petlink",
    },
  },
};
